import React from 'react';
import { navigate, RouteComponentProps } from '@gatsbyjs/reach-router';
import styles from './SpotlightView.module.scss'
import { SpotlightListApi } from '../../model/Search';
import ErrorModal from '../../components/ErrorModal';
import HorizontalList from '../../components/HorizontalList';
import NetworkManager from '../../network/NetworkManager';

interface Props extends RouteComponentProps {

}

interface State {
	spotlightList?: SpotlightListApi
	error?: any
}

class SpotlightView extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
		}
	}

	render(){
		const { error, spotlightList } = this.state
		return (
		<div className={styles.spotlightContainer}>
			{error && <ErrorModal isOpen={true} error={error} onDismiss={()=>{this.setState({error: undefined})}} />}
			<h1 className={styles.title}>WeStock Spotlight Collections</h1>
			<p>Below are some of our favorite brands and products for you to check out. If you like discovering new products please cosider subscribing to our newsletter.</p>
			<button className={styles.subscribeButton} onClick={this.signupPress}>SUBSCRIBE TO NEWSLETTER</button>
			{spotlightList && spotlightList.spotlights.map((spotlight)=> {
				return <HorizontalList showMoreLink={''} hideShowMore={true} title={spotlight.name} description={spotlight.description} brands={spotlight.isProductList == true ? undefined : spotlight.brands} products={spotlight.isProductList == true ? spotlight.products : undefined}></HorizontalList>
			})
			}
		</div>	
		)
	}

	componentDidMount() {
		this.getSpotlightList()
	}

	async getSpotlightList() {
		try {
			const spotlights = await NetworkManager.getSpotlightList(new Date().toISOString().slice(0, 19).replace('T', ' '), 0, 8)
			this.setState({spotlightList: spotlights})
		} catch (error) {
			console.log(error)
			this.setState({error: error})
		}
	}

	signupPress() {
		navigate('/spotlight/signup')
	}

}

export default SpotlightView;