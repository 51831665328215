import { Link } from '@gatsbyjs/reach-router';
import React from 'react';
import styles from './Footer.module.scss'

interface Props {

}

interface State {

}

class Footer extends React.Component<Props, State> {
	render(){
		return (
			<div className={styles.footContainer}>
				<footer className={styles.footer}>
					<a className={styles.link} href="https://www.westock.io/about-us" target="_blank">About Us</a>
					<Link className={styles.link} to="/dl">Download App</Link>
					<Link className={styles.link} to="/crowdstocking">What is Crowdstocking?</Link>
					<div className={styles.smContainer}>
						<a href="https://www.facebook.com/westockapp/"><img className={styles.sm} src={'/icons/fb.png'} /></a>
						<a href="https://www.instagram.com/_westock"><img className={styles.sm} src={'/icons/ig.png'} /></a>
						<a href="https://www.tiktok.com/@trywestock"><img className={styles.sm} src={'/icons/tt.png'} /></a>
					</div>
					<a className={styles.link} href="https://westock.io/privacy" target="_blank">Privacy Policy</a>
					
					<div className={styles.legalBS}>
						© {new Date().getFullYear()} WeStock is a registered trademark.
					</div>
					
				</footer>
			</div>		
		)
	}
}

export default Footer;