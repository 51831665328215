import React from 'react';
import { RouteComponentProps } from '@gatsbyjs/reach-router';
import styles from './RequestView.module.scss'
import ErrorModal from '../../components/ErrorModal';
import NetworkManager from '../../network/NetworkManager';

interface Props extends RouteComponentProps {

}

enum RequestViewState {
	request,
	submitting,
	submitted
}

interface State {
	viewState: RequestViewState
	email: string
	zip: string
	brandProduct: string
	store: string
	error?: any
}

class RequestView extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			viewState: RequestViewState.request,
			email: '',
			zip: '',
			brandProduct: '',
			store: ''
		}
	}

	render(){
		const { viewState, error } = this.state
		return (
			<div className={styles.requestViewContainer}>
				{error && <ErrorModal isOpen={true} error={error} onDismiss={()=>{this.setState({error: undefined})}} />}
				{this.getViewBasedOnState(viewState)}
			</div>
		)
	}

	getViewBasedOnState(viewState: RequestViewState): JSX.Element {
		switch(viewState) {
			case RequestViewState.request:
				return this.getRequestView()
			case RequestViewState.submitting:
				return this.getSubmittingView()
			case RequestViewState.submitted:
				return this.getSubmittedView()
		}
	}

	getRequestView(): JSX.Element {
		const { email, zip, brandProduct, store } = this.state
		return (
			<div className={styles.requestContainer}>
				<h1>Can't find your favorite brand on WeStock or at your local store?</h1>
				<p>Let us know which brands or products you'd like to see and where, and we'll do the rest!</p>
				<form className={styles.requestForm} onSubmit={this.handleSubmit}>
					<div className={styles.inputSection}>
						<label className={styles.inputLabel} htmlFor='email'>Email: </label>
						<input value={email} onChange={(e)=>{this.setState({email: e.currentTarget.value})}} className={styles.inputTextField} type='email' id='email' name='email' maxLength={250} required />
						<div className={styles.helperText}>Email through which we can contact you with updates.</div>
					</div>
					<div className={styles.inputSection}>
						<label className={styles.inputLabel} htmlFor='zip'>Zip/Postal Code: </label>
						<input className={styles.inputTextField} onChange={(e)=>{this.setState({zip: e.currentTarget.value})}} type='text' id='zip' name='zip' maxLength={20} />
						<div className={styles.helperText}>The zip code helps us find your store easier.</div>
					</div>
					<div className={styles.inputSection}>
						<label className={styles.inputLabel} htmlFor='brandProduct'>Brand & Product: </label>
						<input className={styles.inputTextField} onChange={(e)=>{this.setState({brandProduct: e.currentTarget.value})}} type='text' id='brandProduct' name='brandProduct' maxLength={250} required />
						<div className={styles.helperText}>Please be descriptive so we can find the product. EG: UTZ - Original Wavy  </div>
					</div>
					<div className={styles.inputSection}>
						<label className={styles.inputLabel} htmlFor='store'>Store: </label>
						<input className={styles.inputTextField} type='text' onChange={(e)=>{this.setState({store: e.currentTarget.value})}} id='store' name='store' maxLength={250}/>
						<div className={styles.helperText}>Give us the store you would like to see the products in. Store address would be preferred.</div>
					</div>
					<div className={styles.inputSection}> 
						<input className={styles.submitButton} type='submit' value='SUBMIT REQUEST' />
					</div>			
				</form>
			</div>
		)
	}

	getSubmittingView(): JSX.Element {
		return (
			<div className={styles.requestContainer}>
				Your request is on it's way...
			</div>
		)
	}

	getSubmittedView(): JSX.Element {
		return (
			<div className={styles.requestContainer}>
				Thank you for submitting your request.
			</div>
		)
	}

	handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		this.setState({viewState: RequestViewState.submitting})
		const { email, zip, brandProduct, store } = this.state
		try {
			await NetworkManager.submitAnyRequest(email, zip, brandProduct, store)
			this.setState({viewState: RequestViewState.submitted})
		} catch(error) {
			this.setState({viewState: RequestViewState.request})
		}
	}
}

export default RequestView;