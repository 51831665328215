import Axios, { AxiosInstance } from "axios";
import { Brand, BrandApi, BrandListApi, PromotionBrandApi } from "../model/Brand";
import { ListProduct, Product, ProductListApi, TopCategoriesList } from "../model/Product";
import { SearchApi, SearchCategories, SpotlightListApi } from "../model/Search";


let productSearchAbortController: AbortController;
let brandSearchAbortController: AbortController;

class NetworkManager {
	private axiosInstance: AxiosInstance
	constructor() {
		this.axiosInstance = Axios.create({
			baseURL: `${process.env.REACT_APP_API!}`,
			timeout: 60000,
			headers: {
				'Content-Type': "application/json"
			}
		});
	}

	async getProduct(id: number|string): Promise<Product> {
		return (await this.axiosInstance.get(`/p/${id}`)).data
	}

	async getBrand(id: number|string): Promise<BrandApi> {
		return (await this.axiosInstance.get(`/b/${id}`)).data
	}

	async search(string: string): Promise<SearchApi> {
		return (await this.axiosInstance.get(`/s/${string}`)).data
	}

	async getSearchCategories(): Promise<SearchCategories> {
		return (await this.axiosInstance.get('/i/categories')).data
	}

	async getSamplingBrands(): Promise<Brand[]> {
		return (await this.axiosInstance.get('/l/sampling')).data
	}

	async setAnalytic(type: string): Promise<void> {
		return await this.axiosInstance.put('/a/generic', {
			type: type
		})
	}

	async getBrandsList(date: string, page: number, perPage: number): Promise<BrandListApi> {
		return (await this.axiosInstance.post('/b', {
			date: date,
			page: page,
			perPage: perPage
		})).data
	}

	async getProductsList(date: string, page: number, perPage: number): Promise<ProductListApi> {
		return (await this.axiosInstance.post('/p', {
			date: date,
			page: page,
			perPage: perPage
		})).data
	}

	async getTopProductsList(): Promise<ListProduct[]> {
		return (await this.axiosInstance.get('/i/topProducts')).data
	}

	async getSpotlightList(date: string, page: number, perPage: number): Promise<SpotlightListApi> {
		return (await this.axiosInstance.post('/i/spotlight', {
			date: date,
			page: page,
			perPage: perPage
		})).data
	}

	async searchBrand(keyword: string, page: number, perPage: number, subcategoryID?:number): Promise<BrandListApi> {
		if(brandSearchAbortController) {
			brandSearchAbortController.abort()
		}
		brandSearchAbortController = new AbortController()
		return (await this.axiosInstance.get('/s/b', {
			params: {
				keyword: keyword,
				subcategoryID: subcategoryID,
				page: page,
				perPage: perPage
			}, signal: brandSearchAbortController.signal
		})).data
	}

	async searchProduct(keyword: string, page: number, perPage: number, subcategoryID?:number): Promise<ProductListApi> {
		if(productSearchAbortController) {
			productSearchAbortController.abort()
		}
		productSearchAbortController = new AbortController()
		return (await this.axiosInstance.get('/s/p', {
			params: {
				keyword: keyword,
				subcategoryID: subcategoryID,
				page: page,
				perPage: perPage
			}, signal: productSearchAbortController.signal
		})).data
	}

	async getTopCategories(): Promise<TopCategoriesList[]> {
		return (await this.axiosInstance.get('/i/topCategories')).data
	}

	async submitAnyRequest(email: string, zip: string, brandProduct:string, store: string): Promise<void> {
		return await this.axiosInstance.post('/r/a', {
			email: email,
			zip: zip,
			brandProduct: brandProduct,
			store: store
		})
	}

	async signUpNewsLetter(email: string): Promise<void> {
		return await this.axiosInstance.post('/w/newsletter', {
			email: email
		})
	}

	async getPromotions(): Promise<PromotionBrandApi[]> {
		return (await this.axiosInstance.get('/i/promotions')).data
	}
}

export default new NetworkManager();