import { navigate } from '@gatsbyjs/reach-router';
import React from 'react';
import { Brand, ListBrand, PromotionBrandApi } from '../model/Brand';
import { ListProduct, Product } from '../model/Product';
import styles from './HorizontalList.module.scss'

interface Props {
	title: string
	description?: string
	brands?: ListBrand[]
	products?: ListProduct[]
	promos?: PromotionBrandApi[]
	showMoreLink?: string
	hideShowMore?: boolean
}

interface State {

}

class HorizontalList extends React.Component<Props, State> {
	render(){
		const { title, description, brands, products, hideShowMore, showMoreLink, promos } = this.props
		let items = <div></div>
		if(brands != undefined && brands.length != 0) {
			items = <div className={styles.horizontalList}>{brands.map((brand) => {
				return <div key={brand.id} className={styles.brandCard} onClick={()=>navigate(`/b/${brand.id}`, {state: {prevPage: 'discover'}})}>
					<img alt="Brand Logo" onError={(e:any)=>{e.target.onerror = null; e.target.src='./icons/imageNotFound.jpg'}} className={styles.brandLogo} src={process.env.REACT_APP_IMAGE_URL + `/brands/${brand.id}/logo.jpg`}></img>
					<h1>{brand.name}</h1>
				</div>
			})}</div>
		}
		else if(products) {
			items = <div className={styles.horizontalList}>{products.map((product) => {
				return <div key={product.id} className={styles.productCard} onClick={()=>navigate(`/p/${product.id}`, {state: {prevPage: 'discover'}})}>
					<img alt="Product" onError={(e:any)=>{e.target.onerror = null; e.target.src='./icons/imageNotFound.jpg'}} className={styles.productImage} src={process.env.REACT_APP_IMAGE_URL + `/brands/${product.brandID}/products/${product.id}/1.jpg`}></img>
					<h1>{product.name}</h1>
				</div>
			})}</div>
		} else if(promos) {
			items = <div className={styles.horizontalList}>{promos.map((promo) => {
				return <div key={promo.id} className={styles.productCard} onClick={()=>window.open(promo.landingPageUrl + '?medium=web-app&source=homepage', '_blank')}>
					<img alt="Promotion" onError={(e:any)=>{e.target.onerror = null; e.target.src='./icons/imageNotFound.jpg'}} className={styles.brandLogo} src={process.env.REACT_APP_IMAGE_URL + `/brands/${promo.id}/logo.jpg`}></img>
					<h1>{promo.name}</h1>
					{promo.campaignName}
				</div>
			})}</div>
		}
		return (
			<div className={styles.horizontalListContainer}>
				<h2 className={styles.listTitle}>{title}
				{!hideShowMore && showMoreLink && <a href={showMoreLink} className={styles.showMoreButton}>Show More</a>}
				</h2>
				
				{description && <p className={styles.desc}>{description}</p>}
				
				{items}
			</div>	
		)
	}
}

export default HorizontalList;