import React from 'react';
import styles from './ARView.module.scss'

class ARView extends React.Component {

	render(){
		const { qrID } = this.props
		let imageURL = process.env.REACT_APP_IMAGE_URL+'/retail/qr/' + qrID
		return (
			<div className={styles.ARViewContainer}>
				<iframe title="camera" className={styles.cameraFrame} src={`/ar.html?imageURL=${imageURL}`} crossOrigin="anonymous">
				
				</iframe>
				
			</div>
		)
	}

	componentDidMount() {
		

	}

}

export default ARView;