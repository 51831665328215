import React from 'react';
import { RouteComponentProps } from '@gatsbyjs/reach-router';
import styles from './DownloadView.module.scss'

interface Props extends RouteComponentProps {

}

interface State {

}

class DownloadView extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
		}
	}

	render(){
		return (<div className={styles.downloadContainer}>
			<article className={styles.content}>
				<section>
					<h1>
						Download our app
					</h1>
					<p>
						If you would like a permanent place for WeStock download our app onto your device following the device specific instructions below.
					</p>
					<br />
					<h2>
						iOS
					</h2>
					<p>
						Please make sure you are using the Safari web browser, tap the share icon, and then tap on add to home screen. Or if you would like a native application look for us in the <a href="https://apps.apple.com/us/app/westock/id1440435315">app store.</a> 
					</p>
					<h2>
						Android
					</h2>
					<p>
						Using your chrome browser, tap on the add to homescreen banner, or in the kebab menu hit add to homescreen.
					</p>
					<h2>
						MacOS
					</h2>
					<p>
						Using a chrome browser hit the install button on the top right of your address bar.
					</p>
					<h2>
						Windows
					</h2>
					<p>
						Using a chrome, or edge browser hit the install button on the top right of your address bar.
					</p>
					<h2>
						Other
					</h2>
					<p>
						Please search how to install a PWA app on your platform.
					</p>
				</section>
			</article>
		</div>)
	}

}

export default DownloadView;