import React from 'react';
import { navigate, RouteComponentProps } from '@gatsbyjs/reach-router';
import styles from './ProductView.module.scss'
import { Product } from '../../model/Product';
import NetworkManager from '../../network/NetworkManager';
import ErrorModal from '../../components/ErrorModal';
import Loading from '../../components/Loading';

interface Props extends RouteComponentProps {
	productID?: string
	source?: string | null 
	medium?: string | null 
}

interface State {
	loading: boolean
	product?: Product
	error?: any
}

class ProductView extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			loading: true
		}
	}

	render(){
		const { error, product, loading } = this.state
		const location = this.props.location
		let prevPage
		if(location) {
			const locationState: any = location.state
			if(locationState) {
				prevPage = locationState.prevPage
			}
		}
		if(loading) {
			return (
				<Loading />
			)
		}
		if(product) {
			let formURL = process.env.REACT_APP_FORM_URL+`${product.brandID}`
			const { source, medium } = this.props
			if(source && medium) {
				formURL = formURL + `?medium=${medium}&source=${source}`
			} else if(source) {
				formURL = formURL + `?&source=${source}`
			} else if(medium) {
				formURL = formURL + `?&medium=${medium}`
			} else {
				formURL = formURL + '?medium=web-app&source=product-page'
			}
			return (
				<div className={styles.productContainer}>
					{prevPage && <button className={styles.backButton} onClick={this.handleBack}>BACK</button>}
					{error && <ErrorModal isOpen={true} error={error} onDismiss={()=>{this.setState({error: undefined})}} />}
					<div className={styles.productImageContainer}>
						<img onError={(e:any)=>{e.target.onerror = null; e.target.src='/icons/imageNotFound.jpg'}} alt="Product" className={styles.productImage} src={process.env.REACT_APP_IMAGE_URL + `/brands/${product.brandID}/products/${product.id}/1.jpg`}></img>
					</div>
					<div className={styles.productDetailsContainer}>
						<div className={styles.brandLogoContainer}>
							<a href={`/b/${product.brandID}`}>
							<img onError={(e:any)=>{e.target.onerror = null; e.target.src='/icons/imageNotFound.jpg'}} alt="Brand Logo" className={styles.brandLogo} src={process.env.REACT_APP_IMAGE_URL + `/brands/${product.brandID}/logo.jpg`} /> 
							</a>
						</div>
						<h1 className={styles.productName}>{product.name}</h1>
						<h2 className={styles.productDescriptor}>{product.descriptor}</h2>
						<p className={styles.productDescription}>{product.description}</p>
						{product.promoCodeAvailable && <p className={styles.promoCode}>Promo Code Available after request.</p>}
						<a className={styles.requestButton} target="_blank" href={formURL}>Request At Your Local Store</a>
					</div> 
				</div> 
			)
		} else {
			return (
				<div>
					Something went wrong we couldn't find the product.
				</div>
			)
		}
		
	}

	handleBack = ()=> {
		navigate(-1)
	}

	componentDidMount() {
		this.loadProduct(this.props.productID!)
	}

	async loadProduct(id: number|string) {
		try {
			this.setState({loading: true})
			const product = await NetworkManager.getProduct(id)
			this.setState({product: product, loading: false})
		} catch(error) {
			console.log(error)
			this.setState({error: error, loading: false})
		}
	}
}

export default ProductView;