import React from 'react';
import { navigate, RouteComponentProps } from '@gatsbyjs/reach-router';
import styles from './BrandView.module.scss'
import NetworkManager from '../../network/NetworkManager';
import ErrorModal from '../../components/ErrorModal';
import Loading from '../../components/Loading';
import { BrandApi } from '../../model/Brand';

interface Props extends RouteComponentProps {
	brandID?: string
	source?: string | null
	medium?: string | null
}

interface State {
	loading: boolean
	apiBrandResult?: BrandApi
	error?: any
}

class BrandView extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			loading: true
		}
	}

	render(){
		const { error, apiBrandResult, loading } = this.state
		const brand = apiBrandResult?.brand
		const products = apiBrandResult?.products
		const requestCount = apiBrandResult?.requestCount
		const location = this.props.location
		let prevPage
		if(location) {
			const locationState: any = location.state
			if(locationState) {
				prevPage = locationState.prevPage
			}
		}
		if(loading) {
			return (
				<Loading />
			)
		}
		if(brand) {
			let formURL = process.env.REACT_APP_FORM_URL+`${brand.brandID}`
			const { source, medium } = this.props
			if(source && medium) {
				formURL = formURL + `?medium=${medium}&source=${source}`
			} else if(source) {
				formURL = formURL + `?&source=${source}`
			} else if(medium) {
				formURL = formURL + `?&medium=${medium}`
			} else {
				formURL = formURL + '?medium=web-app&source=brand-page'
			}
			return (
				<div className={styles.brandContainer}>
					{error && <ErrorModal isOpen={true} error={error} onDismiss={()=>{this.setState({error: undefined})}} />}
					<div >
						{prevPage && <button className={styles.backButton} onClick={this.handleBack}>BACK</button>}
						<div className={styles.brandInfoContainer}>
							<div className={styles.logoContainer}>
								<img onError={(e:any)=>{e.target.onerror = null; e.target.src='/icons/imageNotFound.jpg'}} alt="Brand Logo" className={styles.brandLogo} src={process.env.REACT_APP_IMAGE_URL + `/brands/${brand.brandID}/logo.jpg`} /> 
							</div>
							<h1 className={styles.brandTitle}>{brand.name}</h1>
							<p className={styles.brandDescription}>{brand.description}</p>
							<div className={styles.brandRequestContainer}>{requestCount} Requests</div>
							{brand.promoCodeAvailable && <p className={styles.promoCode}>Promo Code Available after request.</p>}
							<a className={styles.requestButton} href={formURL} target="_blank">Request At Your Local Store</a>
							<br />
							{brand.sampling && (<><h3 className={styles.samplingTitle}>This brand participates in our sampling program.</h3>
							<a className={styles.requestButton} href={brand.sampling}>Try Samples</a></>)}
							
						</div>
						<div className={styles.productsContainer}>
							<div className={styles.dividerLine}></div>
							<h2 className={styles.productTitle}>All products from {brand.name}</h2>
							<div className={styles.productsGrid}>
								{products?.map((p) => {
									return (
									<div key={p.id} className={styles.productImageContainer}> 
										<img onError={(e:any)=>{e.target.onerror = null; e.target.src='/icons/imageNotFound.jpg'}} alt="Product" onClick={()=>this.handleProductClick(p.id)} className={styles.productImage} src={process.env.REACT_APP_IMAGE_URL + `/brands/${brand.brandID}/products/${p.id}/1.jpg`} />
										<h3>{p.name}</h3> 
									</div>
									)
								})}
							</div>
						</div>
					</div>
				</div>		
			)
		} else {
			return (
				<div>
					Sorry we couldn't find the brand you were looking for.
				</div>
			)
		}
		
	}

	componentDidMount() {
		this.loadBrand(this.props.brandID!)

	}

	handleBack = ()=> {
		navigate(-1)
	}

	async loadBrand(id: number|string) {
		try {
			this.setState({loading: true})
			const brand = await NetworkManager.getBrand(id)
			this.setState({apiBrandResult: brand, loading: false})
		} catch(error) {
			console.log(error)
			this.setState({error: error, loading: false})
		}
	}

	handleProductClick = (productID: number) => {
		navigate(`/p/${productID}`, {state: {prevPage: 'brand'}})
	}
}

export default BrandView;