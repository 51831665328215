import React from 'react';
import { RouteComponentProps } from '@gatsbyjs/reach-router';
import styles from './CrowdstockingView.module.scss'

interface Props extends RouteComponentProps {

}

interface State {

}

class CrowdstockingView extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
		}
	}

	render(){
		return (<div className={styles.crowdstockingContainer}>
			<article className={styles.content}>
				<section>
					<img className={styles.crowdstockImg} src='/crowdstockShelf.jpg'></img>
					<h1 className={styles.sectionTitle}>
						TL;DR
					</h1>
					<p>
						Discover brands, request them locally, see them on store shelves; Crowdstocking!
					</p>
					<h1 className={styles.sectionTitle}>
						Crowdstocking
					</h1>
					<p>
						Did you know that if you ask for a product at your local store, they will listen to you and try to bring it in? Here at WeStock we are trying to change how retail works by giving the power back to you, the consumer.
						<br />
						<br />
						We are empowering ourselves to influence the way our local retail stores look. Simply request products you would like to see at your local stores, and the brand will use that data to get their products stocked in that store. It’s a win-win, and a great way to support new and emerging brands.
						<br />
						<br />
						We want to reward you for supporting these amazing brands, so you will also receive coupons, in-store promotions, and new product drop information before anyone else, just by letting a brand know where you shop.
					</p>
					<h1 className={styles.sectionTitle}>
						The Why
					</h1>
					<p>
						We are shoppers like you and we want to make our weekly grocery trip more exciting. We understand that many new exciting brands are online exclusively, and it’s difficult to try them all; that is why we still believe shopping in-store is the best solution for trying and discovering your next favorite brand.
						<br />
						<br />
						We LOVE product discovery, and what better way to discover new products than by seeing what’s trending in your local community? Scroll through thousands of brands on WeStock to discover your next favorite thing. Don’t see a brand you love listed in the app? You can add it by simply letting us know <a href='r/a'>HERE</a>. After requesting a product, you can also add it to your personal shopping list, which you can share with your friends and followers. 		
					</p>
					<h1 className={styles.sectionTitle}>
						The How
					</h1>
					<p>
						This is the fun and easy part! All you have to do is fill out a brand's product request form and let them know where you want to see their product, or use our web app to find and request products in your local stores. It’s that simple! 
						<br />
						<br />
						We will notify you when your products are available in your area and deliver brand news and rewards directly to your inbox. 
						<br />
						<br />
						Happy brand hunting!
						<br />
						<br />
						-Team WeStock
					</p>
				</section>
			</article>
		</div>)
	}

}

export default CrowdstockingView;