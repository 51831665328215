import React, { ChangeEvent, ReactElement } from 'react';
import { navigate, RouteComponentProps } from '@gatsbyjs/reach-router';
import styles from './DiscoverView.module.scss'
import { SearchApi, SearchCategories, SpotlightListApi } from '../../model/Search';
import NetworkManager from '../../network/NetworkManager';
import ErrorModal from '../../components/ErrorModal';
import { Brand, BrandListApi, PromotionBrandApi } from '../../model/Brand';
import SearchFilter from '../../components/SearchFilter';
import HorizontalList from '../../components/HorizontalList';
import { ListProduct, ProductListApi, TopCategoriesList } from '../../model/Product';

interface Props extends RouteComponentProps {

}

interface State {
	searchText: string
	searchApi?: SearchApi
	samplingBrands: Brand[]
	topBrandList?: BrandListApi
	topProducts?: ListProduct[]
	spotlightList?: SpotlightListApi
	topCategoriesList?: TopCategoriesList[]
	onPromotion: PromotionBrandApi[]
	error?: any
}

class DiscoverView extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		
		this.state = {
			searchText: '',
			samplingBrands: [],
			onPromotion: []
		}
	}
	render(){
		const { onPromotion, error, searchApi, samplingBrands, topBrandList, topProducts, spotlightList, topCategoriesList } = this.state
		let spotlight
		if(spotlightList && spotlightList.spotlights && spotlightList.spotlights.length > 0) {
			spotlight = spotlightList.spotlights[0]
		}
		return (
			<div className={styles.searchContainer}>
				{error && <ErrorModal isOpen={true} error={error} onDismiss={()=>{this.setState({error: undefined})}} />}
				{/*<div className={styles.searchFieldContainer}>
					<input className={styles.searchField} type="text" value={searchText} onChange={this.handleSearchChange} placeholder="Search for keywords or products" />
					<img className={styles.searchIcon} src='./icons/searchIcon.png' />
		</div>*/}
				<img className={styles.bannerImage} src='/icons/blog_ban.jpg'></img>
				<p className={styles.requestSection}>
					Have a brand you would love to see on WeStock and your local shelves? 
				</p>
				<button className={styles.requestButton} onClick={this.handleRequestPress}>LET US KNOW!</button>
				<br />
				<h1 className={styles.pageTitle}>Brands On Promotion!</h1>
				{onPromotion && onPromotion.length != 0 && <HorizontalList title='Text Rebates' showMoreLink='/spotlight/promos' promos={onPromotion}></HorizontalList>}
				{searchApi && this.displaySearchResults(searchApi)}
				<h1 className={styles.pageTitle}>Discover WeStock Brands</h1>
				{spotlight && <HorizontalList showMoreLink={'/spotlight'} title={`${spotlight.name}`} description={spotlight.description} brands={spotlight.isProductList == true ? undefined : spotlight.brands} products={spotlight.isProductList == true ? spotlight.products : undefined}></HorizontalList>}
				{topBrandList && <HorizontalList showMoreLink={'/b'} title={"Top Brands"} brands={topBrandList.brands}></HorizontalList>}
				{topProducts && <HorizontalList showMoreLink={'/p'} title={"Top Products"} products={topProducts}></HorizontalList>}
				<h1 className={styles.pageTitle}>Most Requested This Month By Category</h1>
				{topCategoriesList && topCategoriesList.map((tc) => {return (
					<HorizontalList key={tc.category.id} showMoreLink='' hideShowMore={true} title={tc.category.name.toLocaleUpperCase()} products={tc.products}></HorizontalList>
				)})}
									
			</div>		
		)
	}

	handleRequestPress() {
		navigate('/r/a')
	}

	componentDidMount() {
		// this.loadSamplingBrands()
		this.loadTopBrands()
		this.getSpotlightList()
		this.loadTopProducts()
		this.getTopCategories()
		this.getPromotions()
	}

	async getPromotions() {
		try {
			const promotions = await NetworkManager.getPromotions()
			this.setState({onPromotion: promotions})
		} catch (error) {
			console.log(error)
			this.setState({error: error})
		}
	}

	async getTopCategories() {
		try {
			const categories = await NetworkManager.getTopCategories()
			this.setState({topCategoriesList: categories})
		} catch (error) {
			console.log(error)
			this.setState({error: error})
		}
	}

	async getSpotlightList() {
		try {
			const spotlights = await NetworkManager.getSpotlightList(new Date().toISOString().slice(0, 19).replace('T', ' '), 0, 1)
			this.setState({spotlightList: spotlights})
		} catch (error) {
			console.log(error)
			this.setState({error: error})
		}
	}

	async loadTopBrands() {
		try {
			const topBrands = await NetworkManager.getBrandsList(new Date().toISOString().slice(0, 19).replace('T', ' '), 0, 8)
			this.setState({topBrandList: topBrands})
		} catch (error) {
			console.log(error)
			this.setState({error: error})
		}
	}

	async loadTopProducts() {
		try {
			const topProducts = await NetworkManager.getTopProductsList()
			this.setState({topProducts: topProducts})
		} catch (error) {
			console.log(error)
			this.setState({error: error})
		}
	}

	async loadSamplingBrands() {
		try {
			const samplingBrands = await NetworkManager.getSamplingBrands()
			this.setState({samplingBrands: samplingBrands})
		} catch (error) {
			console.log(error)
			this.setState({error: error})
		}
	}

	handleSearchChange = (event:ChangeEvent<HTMLInputElement>) => {
		const searchText = event.target.value
		this.setState({searchText: searchText})

		if(searchText != '') {
			this.search(searchText)
		} else {
			this.setState({searchApi: undefined})
		}
	}

	async search(text:string) {
		try {
			const searchApi = await NetworkManager.search(text)
			if(searchApi.searchTerm = this.state.searchText) {
				this.setState({searchApi: searchApi})
			}			
		} catch (error) {
			console.log(error)
			this.setState({error: error})
		}
	}

	displaySamplingBrands(brands: Brand[]): ReactElement {
		if(brands.length == 0) {
			return <></>
		}
		return (
		<div className={styles.samplingContainer}>
			<h1>Sample these amazing brands!</h1>
			<div className={styles.brandGrid}>
				{brands.map((b) => {
					return <div key={b.brandID} className={styles.brandContainer} onClick={()=>this.handleBrandClick(b.brandID+'')}>
						<img alt="Brand Logo" className={styles.brandLogo} src={process.env.REACT_APP_IMAGE_URL + `/brands/${b.brandID}/logo.jpg`} /> 
						<h3 className={styles.brandName}>{b.name}</h3>
					</div>
				})}
			</div>
		</div>
		)
	}

	displaySearchResults(searchApi: SearchApi): ReactElement {
		const brands = searchApi.brands
		const products = searchApi.products
		let brandElement
		if(brands.length != 0) {
			brandElement = (
			<table className={styles.resultsTable}>
				<tr><th>Brand</th></tr>
				{brands.map((b)=>{
					return <tr key={b.id}><td onClick={()=>this.handleBrandClick(b.id)}>{b.name}</td></tr>
				})}
			</table>
			)
		}
		let productElement
		if(products.length != 0) {
			productElement = (
			<table className={styles.resultsTable}>
				<tr><th>Product</th></tr>
				{products.map((p)=>{
					return <tr key={p.id}><td onClick={()=>this.handleProductClick(p.id)}>{p.name}</td></tr>
				})}
			</table>
			)
		}

		return (<div>
			{brandElement}
			{productElement}
		</div>)
	}

	handleBrandClick = (brandID: string) => {
		navigate(`/b/${brandID}`, {state: {prevPage: 'search'}})
	}
	handleProductClick = (productID: string) => {
		navigate(`/p/${productID}`, {state: {prevPage: 'search'}})
	}
}

export default DiscoverView;