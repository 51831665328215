import React from 'react';
import { navigate, RouteComponentProps } from '@gatsbyjs/reach-router';
import styles from './SpotlightView.module.scss'
import ErrorModal from '../../components/ErrorModal';
import NetworkManager from '../../network/NetworkManager';
import { PromotionBrandApi } from '../../model/Brand';

interface Props extends RouteComponentProps {

}

interface State {
	promosList?: PromotionBrandApi[]
	error?: any
}

class PromosView extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
		}
	}

	render(){
		const { error, promosList } = this.state
		return (
		<div className={styles.promosContainer}>
			{error && <ErrorModal isOpen={true} error={error} onDismiss={()=>{this.setState({error: undefined})}} />}
			<h1 className={styles.title}>WeStock brands currently running text rebates</h1>
			<p>Discover your next favorite product while getting reimbursed for your purchase. Sign up to get instructions for the offers!</p>
			{promosList && promosList.map((promo)=> {
				return (
					<div className={styles.promoContainer} key={promo.landingPageUrl}>
						<img alt="Brand Logo" onError={(e:any)=>{e.target.onerror = null; e.target.src='/icons/imageNotFound.jpg'}} className={styles.brandLogo} src={process.env.REACT_APP_IMAGE_URL + `/brands/${promo.id}/logo.jpg`}></img>
						<div className={styles.promoTextContainer}>
							<div className={styles.promoText}><b>{promo.name}</b> {promo.campaignName} </div>
							<button className={styles.signUp} onClick={()=>window.open(promo.landingPageUrl + '?medium=web-app&source=promo-page', '_blank')}>SIGN UP</button>
							</div>
					</div>)
			})
			}
		</div>	
		)
	}

	componentDidMount() {
		this.getPromotions()
	}

	async getPromotions() {
		try {
			const promos = await NetworkManager.getPromotions()
			this.setState({promosList: promos})
		} catch (error) {
			console.log(error)
			this.setState({error: error})
		}
	}

	signupPress() {
		navigate('/spotlight/signup')
	}

}

export default PromosView;