import { Link, LinkGetProps } from '@gatsbyjs/reach-router';
import React from 'react';
import styles from './Header.module.scss'
import './hamburgers/hamburgers.scss'

interface Props {

}

interface State {
	showHam: boolean
}

class Header extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			showHam: false
		}
	}

	render(){
		const { showHam } = this.state
		let hamburgerClass = `${styles.hamburgerMenu} hamburger hamburger--squeeze`
		if(showHam) {
			hamburgerClass = hamburgerClass + ' is-active'
		}
		let navBarClass = styles.navBar
		if(showHam) {
			navBarClass = navBarClass + ` ${styles.navBarActive}`
		}
		return (
			<div className={styles.headContainer}>
				<header className={styles.header}>
					<a href={'/'}>
						<img className={styles.headerLogo} alt="Home" src={'/headerLogo.png'} />
					</a>
					<nav className={navBarClass}>
						<ul>
							<li><Link to="/" getProps={getLinkRootProps} onClick={()=>{if(showHam == true){ this.hamburgerPressed() }}}>Discover</Link></li>
							<li><Link to="/tiktok" getProps={getLinkProps} onClick={()=>{if(showHam == true){ this.hamburgerPressed() }}}>TikTok</Link></li>
							<li><Link to="/b" getProps={getLinkProps} onClick={()=>{if(showHam == true){ this.hamburgerPressed() }}}>Brands</Link></li>
							<li><Link to="/p" getProps={getLinkProps} onClick={()=>{if(showHam == true){ this.hamburgerPressed() }}}>Products</Link></li>
							<li><Link to="/crowdstocking" getProps={getLinkProps} onClick={()=>{if(showHam == true){ this.hamburgerPressed() }}}>Crowdstocking?</Link></li>
						</ul>
					</nav>
					<button onClick={()=>{this.hamburgerPressed()}} className={hamburgerClass} type="button">
							<span className={'hamburger-box'}>
								<span className={'hamburger-inner'}></span>
							</span>
					</button>
				</header>
			</div>	
		)
	}

	hamburgerPressed = () => {
		let { showHam } = this.state
		showHam = !showHam

		if(showHam == true) {
			const body = document.body
			body.style.height = '100%'
			body.style.overflow = 'hidden'
			
		} else {
			const body = document.body
			body.removeAttribute('style')
		}
		this.setState({showHam: showHam})
	}
}

function getLinkRootProps(props: LinkGetProps) {
	if(props.isCurrent === true) {
		return {className: styles.selectedLink}
	}
	return {className: styles.navLink}
}

function getLinkProps(props: LinkGetProps) {
	if(props.isPartiallyCurrent === true) {
		return {className: styles.selectedLink}
	}
	return {className: styles.navLink}
}

export default Header;