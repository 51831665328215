import React from 'react';
import { RouteComponentProps } from '@gatsbyjs/reach-router';
import styles from './SpotlightView.module.scss'
import { SpotlightListApi } from '../../model/Search';
import ErrorModal from '../../components/ErrorModal';
import HorizontalList from '../../components/HorizontalList';
import NetworkManager from '../../network/NetworkManager';

interface Props extends RouteComponentProps {

}

enum NewsletterSignupViewState {
	subscribe,
	subscribing,
	subscribed
}

interface State {
	viewState: NewsletterSignupViewState
	email: string
	error?: any
}

class NewsletterSignupView extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			viewState: NewsletterSignupViewState.subscribe,
			email: ''
		}
	}

	render(){
		const { error, viewState } = this.state
		return (
		<div className={styles.spotlightContainer}>
			{error && <ErrorModal isOpen={true} error={error} onDismiss={()=>{this.setState({error: undefined})}} />}
			<h1 className={styles.title}>WeStock Newsletter</h1>
			<p>Want to discover and get discounts on cool products? We have all the best new brands on WeStock. Click the link below to subscribe to the WeStock newsletter to find great new brands and unlock promotion on products you’ll love. </p>
			{this.getViewBasedOnState(viewState)}
		</div>	
		)
	}

	getViewBasedOnState(viewState: NewsletterSignupViewState): JSX.Element {
		switch(viewState) {
			case NewsletterSignupViewState.subscribe:
				return this.getSubscribeView();
			case NewsletterSignupViewState.subscribing:
				return this.getSubscribingView();
			case NewsletterSignupViewState.subscribed:
				return this.getSubscribedView();
		}
	}

	getSubscribeView(): JSX.Element {
		const { email } = this.state
		return (
			<div>
				<form onSubmit={this.subscribePress}>
					<div className={styles.inputSection}>
						<label className={styles.inputLabel} htmlFor='email'>Email: </label>
						<input className={styles.inputTextField} value={email} onChange={(e)=>this.setState({email: e.currentTarget.value})} type='email' id='email' name='email' required/>
					</div>
					<button className={styles.subscribeButton2}>SUBSCRIBE</button>
				</form>
			</div>
		)
	}

	getSubscribingView(): JSX.Element {
		return (
			<div className={styles.requestContainer}>
				Submitting...
			</div>
		)
	}

	getSubscribedView(): JSX.Element {
		return (
			<div className={styles.requestContainer}>
				Thank you for subscribing!
			</div>
		)
	}

	subscribePress = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		const { email } = this.state
		this.setState({viewState: NewsletterSignupViewState.subscribing})
		try {
			await NetworkManager.signUpNewsLetter(email)
			this.setState({viewState: NewsletterSignupViewState.subscribed})
		} catch (error) {
			this.setState({viewState: NewsletterSignupViewState.subscribe, error: error})
		}
	}
}

export default NewsletterSignupView;