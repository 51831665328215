import React from 'react';
import { RouteComponentProps } from '@gatsbyjs/reach-router';

interface Props extends RouteComponentProps {

}

interface State {

}

class Loading extends React.Component<Props, State> {
	render(){
		return (
			<div>
				Loading...
			</div>		
		)
	}
}

export default Loading;