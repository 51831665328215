import React from 'react';
import './App.css';
import { Router } from '@gatsbyjs/reach-router';
import ProductView from './views/product/ProductView';
import BrandView from './views/brand/BrandView';
import DiscoverView from './views/discover/DiscoverView';
import NetworkManager from './network/NetworkManager';
import Header from './components/Header';
import Footer from './components/Footer';
import ARView from './views/ar/ARView';
import CrowdstockingView from './views/crowdstocking/CrowdstockingView';
import DownloadView from './views/download/DownloadView';
import BrandsView from './views/brands/BrandsView';
import SpotlightView from './views/spotlight/SpotlightView';
import ProductsView from './views/products/ProductsView';
import RequestView from './views/request/RequestView';
import NewsletterSignupView from './views/spotlight/NewsletterSignupView';
import TikTokView from './views/tiktok/TikTokView';
import PromosView from './views/spotlight/PromosView';

interface Props {

}

interface State {
	medium?: string | null
	source?: string | null
}

class App extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		
		this.state = {

		}
	}

  	render() { 
	  return (
			<div className="app">
				<Header />
				<Router className="appRouter">
					<DiscoverView path='/' />
					<SpotlightView path="/spotlight" />
					<NewsletterSignupView path="/spotlight/signup" />
					<ProductsView path='/p' />
					<ProductView path="/p/:productID" source={this.state.source} medium={this.state.medium} />
					<BrandsView path="/b" />
					<BrandView path="/b/:brandID" source={this.state.source} medium={this.state.medium} />
					<CrowdstockingView path="/crowdstocking" />
					<ARView path="/ar/:qrID" />
					<DownloadView path='/dl' />
					<RequestView path="/r/a" />
					<TikTokView path="/tiktok" />
					<PromosView path="/spotlight/promos" />
				</Router>
				<Footer />
			</div>
		)
	}
	componentDidMount() {
		const queryString = window.location.search
		if(queryString) {
			const urlParams = new URLSearchParams(queryString)
			const medium = urlParams.get('medium')
			const source = urlParams.get('source')
			if(medium && source) {
				if(medium == 'sampling' && source == 'sampling-qr') {
					NetworkManager.setAnalytic('sampling-qr-scan')
				}
			}

			this.setState({medium: medium, source: source})
		}
	}
}


export default App;
