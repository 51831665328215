import { Link } from '@gatsbyjs/reach-router';
import React from 'react';
import { SearchCategories } from '../model/Search';
import NetworkManager from '../network/NetworkManager';
import ErrorModal from './ErrorModal';
import styles from './SearchFilter.module.scss'

interface Props {
	searchCategories?: SearchCategories
	selectedSubCategory?: {id: number, name: string}
	onSubcategoryChange: (subcategory?: {id: number, name: string})=>void
}

interface State {
	openedCategory?: number
}

class SearchFilter extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
		}
	}

	render(){
		const { openedCategory } = this.state
		const { searchCategories, selectedSubCategory } = this.props
		return (
			<div className={styles.searchFilter}>
				<h3>Categories</h3>
				{searchCategories && searchCategories.categories && <table className={styles.categoriesTable}>
					<tbody>
						{searchCategories.categories.map((cat) => {
							const isSelected = cat.category.id == openedCategory
							let selectedSubCat = false
							if(isSelected == false) {
								for(let i = 0; i < cat.subCategories.length; i ++) {
									if(selectedSubCategory?.id == cat.subCategories[i].id) {
										selectedSubCat = true
										break;
									}
								}
							}
							return <tr key={cat.category.id} onClick={()=>this.openCategory(cat.category.id)}>
								<td className={`${styles.categoryCell} ${selectedSubCat == true ? styles.categoryCellSelected : ''}`}>
									<div className={styles.categoryTitle}>
										<h3>{cat.category.name.toUpperCase()}</h3>
										<div className={styles.titleControls}>
											<div className={isSelected ? styles.chevronUp : styles.chevronDown}></div>
										</div>
									</div>									
									{isSelected && 
										<table className={styles.subCategoriesTable}>
											<tbody>
											{cat.subCategories.map((subCat) => {
												const isSCSelected = subCat.id == selectedSubCategory?.id
												return <tr onClick={(e)=>{e.stopPropagation();this.selectSubCategory(subCat);}} key={subCat.id}>
													<td className={styles.subCategoryCell}>{subCat.name}{isSCSelected && <div className={styles.checkmark}></div>}</td>
												</tr>
											})}
											</tbody>
										</table>
									}
								</td>
							</tr>
						})}
						</tbody>
					</table>
				}
			</div>		
		)
	}

	openCategory = (categoryID: number) => {
		const { openedCategory } = this.state
		if(openedCategory == categoryID) {
			this.setState({openedCategory: undefined})
		} else {
			this.setState({openedCategory: categoryID})
		}
		
	}

	selectSubCategory = (subcategory: {id: number, name: string}) => {
		this.props.onSubcategoryChange(subcategory)
	}
}

export default SearchFilter;