import React from 'react';
import { RouteComponentProps } from '@gatsbyjs/reach-router';
import styles from './TikTokView.module.scss'

interface Props extends RouteComponentProps {

}

interface State {

}

class TikTokView extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
		}
	}

	componentDidMount(): void {
		const script = document.createElement('script');
		script.src = 'https://www.tiktok.com/embed.js';
		script.id = 'tiktokScript';
		document.body.appendChild(script);
	}

	componentWillUnmount(): void {
		const scriptElement = document.getElementById('tiktokScript')
		if(scriptElement) {
			document.body.removeChild(scriptElement)
		}
	}

	render(){
		return (
			<div className={styles.tikTokContainer}>
				<blockquote className="tiktok-embed" cite="https://www.tiktok.com/@trywestock" data-unique-id="trywestock" data-embed-type="creator" style={{maxWidth: "720px", minWidth: '288px'}} >
					<section> 
						<a target="_blank" href="https://www.tiktok.com/@trywestock?refer=creator_embed">@trywestock</a> 
					</section> 
				</blockquote>
			</div>
		)
	}

}

export default TikTokView;