import React, { ChangeEvent, ChangeEventHandler } from 'react';
import { navigate, RouteComponentProps } from '@gatsbyjs/reach-router';
import styles from './ProductsView.module.scss';
import ErrorModal from '../../components/ErrorModal';
import NetworkManager from '../../network/NetworkManager';
import InfiniteScroll from 'react-infinite-scroll-component';
import SearchFilter from '../../components/SearchFilter';
import { SearchCategories } from '../../model/Search';
import { ListProduct } from '../../model/Product';
import axios from 'axios';

interface Props extends RouteComponentProps {

}

interface State {
	discriminator: 'Products-State'
	products?: ListProduct[]
	showCategories: boolean
	date: string
	page: number
	perPage: number
	end: boolean
	error?: any
	searchText: string
	selectedSubcategory?: {id: number, name: string}
	searchCategories?: SearchCategories
	keywordCategories?: SearchCategories
}

class ProductsView extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		let state = window.history.state
		
		this.state = state && state.discriminator === 'Products-State' ? state : {
			discriminator: 'Products-State',
			date: new Date().toISOString().slice(0, 19).replace('T', ' '),
			showCategories: false,
			page: 0,
			perPage: 25,
			end: false,
			searchText: ''
		}
	}

	render(){
		let { products, error, perPage, end, searchText, selectedSubcategory, searchCategories, keywordCategories, showCategories } = this.state
		if(!products) {
			products = []
		}

		let title = 'The most requested products in the last 30 days' 
		let categories = searchCategories
		if(searchText != '') {
			title = 'Searching by keyword'
			if(keywordCategories) {
				categories = keywordCategories
			}
		}

		if(selectedSubcategory) {
			if(searchText != '') {
				title = `${title} and filtering products in ${selectedSubcategory.name}`
			} else {
				title = `Filtering products in ${selectedSubcategory.name}`
			}
			
		}

		let filterContainerClass = styles.searchFilterContainer
		if(showCategories) {
			filterContainerClass = filterContainerClass + ` ${styles.searchFilterContainerActive}`
		}

		return (<div className={styles.productsContainer}>
			{error && <ErrorModal isOpen={true} error={error} onDismiss={()=>{this.setState({error: undefined})}} />}
			<div className={filterContainerClass}>
				<SearchFilter selectedSubCategory={selectedSubcategory} searchCategories={categories} onSubcategoryChange={this.handleSubcategoryChange}></SearchFilter>
				<div className={styles.closeIconContainer} onClick={this.toggleCategories}>
					<span className={styles.closeIcon}>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" enable-background="new 0 0 40 40">
						<line x1="10" y1="10" x2="30" y2="30"  stroke-width="2.5" stroke-linecap="round" stroke-miterlimit="10"></line>
						<line x1="30" y1="10" x2="10" y2="30" stroke-width="2.5" stroke-linecap="round" stroke-miterlimit="10"></line>    
						</svg>
					</span>
				</div>
			</div>
			<div className={styles.scrollContainer}>
				<div className={styles.searchFieldContainer}>
					<input className={styles.searchField} type="text" value={searchText} onChange={this.handleSearchChange} placeholder="Search products" />
					<img className={styles.searchIcon} src='/icons/searchIcon.png' />
				</div>
				<div className={styles.titleContainer}>
					<h1>{title}</h1>
					<div className={styles.sortByContainer}>Sorting By: Most Requested</div>
					<button className={styles.filterButton} onClick={this.toggleCategories}>Show Categories</button>
				</div>
				{products.length != 0 ? 
				<InfiniteScroll
				dataLength={products.length} //This is important field to render the next data
				next={this.loadNext}
				hasMore={!end}
				loader={<h4>Loading...</h4>}
				style={{overflow: 'visible'}}
				endMessage={
				<p style={{ textAlign: 'center' }}>
					<b>Yay! You have seen it all</b>
				</p>
				}>
					<div className={styles.contentList}>
						{products.map((product) => {
							console.log(product)
							return (
								<div key={product.id} className={styles.productCard} onClick={()=>this.handleProductClick(product.id)}>
									<img alt="Product" onError={(e:any)=>{e.target.onerror = null; e.target.src='/icons/imageNotFound.jpg'}} className={styles.productImage} src={process.env.REACT_APP_IMAGE_URL + `/brands/${product.brandID}/products/${product.id}/1.jpg`}></img>
									<div className={styles.productTitle}>
										<img onError={(e:any)=>{e.target.onerror = null; e.target.src='/icons/imageNotFound.jpg'}} alt="Brand Logo" className={styles.brandLogo} src={process.env.REACT_APP_IMAGE_URL + `/brands/${product.brandID}/logo.jpg`}></img>
										<div className={styles.productText}>
											<h1 className={styles.brandName}>{product.brandName}</h1>
											<h1 className={styles.productName}>{product.name}</h1>
										</div>
									</div>
								</div>
							)
						})}
					</div>
				</InfiniteScroll> : <div className={styles.noSearchContainer}>
					<img className={styles.noSearch} src='/icons/noSearch.png'></img>
					<p>No Search Results 😔</p> 
					Let us know what product you are looking for and we will try to get it to a store near you!
					<br />
					<button className={styles.noProductButton} onClick={this.handleRequestPress}>PRODUCT REQUEST</button>
				</div>}
			</div>
		</div>)
	}

	componentDidMount() {
		const { date, page, perPage } = this.state
		if(this.state.products == undefined) {
			this.loadProducts(date, page, perPage)
		this.loadSearchCategories()
		}
	}

	async loadSearchCategories() {
		try {
			const searchCategories = await NetworkManager.getSearchCategories()
			this.setState({searchCategories: searchCategories})
		} catch (error) {
			console.log(error)
			this.setState({error: error})
		}
	}

	loadProducts = async (date: string, page: number, perPage: number) => {
		try {
			const productListApiNew = await NetworkManager.getProductsList(date, page, perPage)
			let { products } = this.state
			if(date == productListApiNew.date && products) {
				products = products.concat(productListApiNew.products)
			} else {
				products = productListApiNew.products
			}
			let end = true
			if(productListApiNew.products.length == perPage) {
				end = false
			}
			this.setState({products: products, end: end})
		} catch (error) {
			if(axios.isCancel(error)) {
				return
			}
			console.log(error)
			this.setState({error: error})
		}
	}

	loadNext = () => {
		const { selectedSubcategory, searchText, page, perPage, date } = this.state
		const nextPage = page + 1
		this.setState({page: nextPage})
		if(selectedSubcategory || searchText != '') {
			this.search(searchText, nextPage, perPage, selectedSubcategory?.id)
		} else {
			this.loadProducts(date, nextPage, perPage)
		}
	}

	handleSearchChange = (event:ChangeEvent<HTMLInputElement>) => {
		const searchText = event.target.value
		this.setState({searchText: searchText, selectedSubcategory: undefined, page: 0, products: []})
		this.search(searchText, 0, this.state.perPage, undefined)
	}

	handleSubcategoryChange = (subcategory?: {id: number, name: string}) => {
		const { selectedSubcategory } = this.state
		let newSubCategory
		if(subcategory?.id == selectedSubcategory?.id) {
			newSubCategory = undefined
		} else {
			newSubCategory = subcategory
		}
		if(this.state.showCategories == true) {
			this.toggleCategories()
		}		
		this.setState({selectedSubcategory: newSubCategory, page: 0, products: []})
		this.search(this.state.searchText, 0, this.state.perPage, newSubCategory?.id)
	}

	search = async (keyword: string, page: number, perPage: number, subcategoryID?: number) => {
		if(subcategoryID == undefined && keyword == '') {
			this.loadProducts(this.state.date, 0, perPage)
			return
		}
		try {
			const productApi = await NetworkManager.searchProduct(keyword, page, perPage, subcategoryID)
			let { products } = this.state

			if(productApi.keyword == keyword && productApi.subcategoryID == subcategoryID) {
				products = products?.concat(productApi.products)
			} else {
				products = productApi.products
			}
			let end = true
			if(productApi.products.length == perPage) {
				end = false
			}
			this.setState({products: products, end: end, keywordCategories: productApi.categories})
		} catch (error) {
			if(axios.isCancel(error)) {
				return
			}
			console.log(error)
			this.setState({error: error})
		}
	}

	handleProductClick = (productID: number) => {
		if(this.props.location) {
			window.history.replaceState(this.state, 'ProductsView', '/p')
		}
		navigate(`/p/${productID}`, {state: {prevPage: 'products'}})
	}

	toggleCategories = () => {
		let { showCategories } = this.state
		showCategories = !showCategories

		if(showCategories == true) {
			const body = document.body
			body.style.height = '100%'
			body.style.overflow = 'hidden'
			
		} else {
			const body = document.body
			body.removeAttribute('style')
		}
		window.scrollTo(0,0)
		this.setState({showCategories: showCategories})
	}

	handleRequestPress() {
		navigate('/r/a')
	}
}

export default ProductsView;